<template>
  <div>

    <b-row>

      <b-col
        xl="4" cols="12"
      >
        <b-card>
          <b-card-header>

          <b-card-title
          >
            <p
              class="h5"
            >
              Resumo da Recarga
            </p>
          </b-card-title>
            
          <b-card-text>
            <b-link
              :to="{ name: 'financeiro-recarregar-planos'}"
            >
              <feather-icon
                  size="16"
                  icon="EditIcon"
                  class="text-dark"
                />
              </b-link>
          </b-card-text>

          </b-card-header>
          <b-card-body>
            <p class="small p-0 m-0">Plano</p>
            <p class="h6 p-0 mt-0">{{ planoSelecionado.nome }}</p>
            <p class="small p-0 m-0">Valor</p>
            <p class="h6 p-0 m-0">{{ (planoSelecionado.valor / 100) | currency }}</p>
            <b-button
              class="mt-1"
              variant="primary"
              @click="recarregarPix()"
            >
              Pagar com Pix
            </b-button>
          </b-card-body>
         
        </b-card>
      </b-col>

      <b-col 
        xl="5"
      >
        <b-card
          v-if="pixGerado"
          class="justify-content-center align-items-center text-center"
        >

          <b-card-body>

            <div v-if="pixContador != 0">
              <p>Para realizar o pagamento via pix <b>faça a leitura do QR Code</b> no aplicativo do seu banco ou <b>clique no botão para copiar o código.</b></p>
              
              <b-row>
                <b-col cols="12">
                  <b-button
                    variant="primary"
                    @click="copiarPixCodigo"
                  >
                    Copiar código copia/cola
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <img 
                    :src="pixQrcode" 
                    width="200" 
                    class="mt-1"
                  />
                </b-col>
              </b-row>

              <p><b>{{ minutes }}:{{ seconds }}</b></p>

              <b-form-textarea
                v-model="pixCodigo"
                rows="2"
                class="mt-2"
                ref="textCodigo"
                v-on:focus="$event.target.select()"
              />

              <b-button
                class="mt-2"
                variant="dark"
                ref="cardPix"
              >
                Confirmar Pagamento
              </b-button>
            </div>
            <div v-else>

              <p class="text-danger"><b>O QR Code expirou!</b> Gere um novo para poder realizar o pagamento no Pix!</p>

              <b-button
                class="mt-1"
                variant="primary"
                @click="recarregarPix()"
              >
                Gerar novo QR Code
              </b-button>

            </div>

          </b-card-body>

            
        </b-card>
      </b-col>
    </b-row>

    <!-- Modal Completar Endereço -->

  <b-modal
      id="modal-completar-endereco"
      ref="modalCompletarEndereco"
      ok-only
      ok-title="Cadastrar Endereço"
      centered
      no-close-on-backdrop
      size="md"
      title="Atualização Cadastral"
      @ok="cadastrarEndereco"
      @hide="redirecionarPlanos"
    >

      <template v-slot:modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="invalid || respostaApi"
          @click="cadastrarEndereco"
        >
          Cadastrar Endereço
          <b-spinner
            v-if="respostaApi"
            small
            label="Carregando"
          />
        </b-button>
      </template>

      <h4>Complete o seu endereço</h4>

      <b-alert
        variant="primary"
        show
        class="mt-1"
      >
        <div class="alert-body">
          <span><strong>Atualize os seus dados!</strong> Para continuar com a assinatura complete o seu cadastro.</span>
        </div>
      </b-alert>


      <validation-observer
        #default="{invalid}"
        ref="enderecoForm"
      >

        <!-- Form -->
        <b-form
          ref="enderecoForms"
          class="mt-2"
          @submit.prevent="negociarHitCadastrar"
        >

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Cep -->
              <b-form-group
                label="CEP"
                label-for="enderecoCepLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoCep"
                  rules="required"
                >
                  <the-mask
                    id="enderecoCep"
                    v-model="enderecoCep"
                    :mask="['#####-###']"
                    :masked="false"
                    :state="errors.length > 0 ? false:null"
                    placeholder="00000-000"
                    class="form-control"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Cep -->

            </b-col>

            <b-col>

              <!-- Endereço -->
              <b-form-group
                label="Endereço"
                label-for="enderecoLogradouroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoLogradouro"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoLogradouro"
                    v-model="enderecoLogradouro"
                    name="enderecoLogradouro"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Endereço -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="4"
            >

              <!-- Número -->
              <b-form-group
                label="Número"
                label-for="enderecoNumeroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoNumero"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoNumero"
                    v-model="enderecoNumero"
                    name="enderecoNumero"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Número -->

            </b-col>

            <b-col>

              <!-- Bairro -->
              <b-form-group
                label="Bairro"
                label-for="enderecoBairroLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoBairro"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoBairro"
                    v-model="enderecoBairro"
                    name="enderecoBairro"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Bairro -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="12"
            >

              <!-- Complemento -->
              <b-form-group
                label="Complemento"
                label-for="enderecoComplementoLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoComplemento"
                >

                  <b-form-input
                    id="enderecoComplemento"
                    v-model="enderecoComplemento"
                    name="enderecoComplemento"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Número -->

            </b-col>

          </b-row>

          <b-row>

            <b-col
              cols="8"
            >

              <!-- Cidade -->
              <b-form-group
                label="Cidade"
                label-for="enderecoCidadeLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoCidade"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoCidade"
                    v-model="enderecoCidade"
                    name="enderecoCidade"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Cidade -->

            </b-col>

            <b-col>

              <!-- Estado -->
              <b-form-group
                label="Estado"
                label-for="enderecoEstadoLabel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="enderecoEstado"
                  rules="required"
                >

                  <b-form-input
                    id="enderecoEstado"
                    v-model="enderecoEstado"
                    name="enderecoEstado"
                    :state="errors.length > 0 ? false:null"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estado -->

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

    <!-- basic modal -->
    <b-modal
      id="gerandoPix"
      ref="gerandoPix"
      centered
      title="Gerando Pix"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
    >
      <b-card-text class="text-center p-2">
        <strong>O QR Code está sendo gerado.</strong><br>
        <b-spinner
          class="mt-2"
          variant="primary"
        />
      </b-card-text>
    </b-modal>

  </div>

</template>

<script>
import Vue from 'vue'

import {
  BAlert, BRow, BImg, BCol, BCard, BCardHeader, BCardBody, BCardFooter, BForm, BFormTextarea, BFormGroup, BInputGroup, BFormInput, BCardText, BCardTitle, BInputGroupPrepend, BButton, BBadge, BLink, BSpinner, BModal,
} from 'bootstrap-vue'

import router from '@/router'

import VueCreditCard from '@fracto/vue-credit-card';

import { Validator, ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate'

import { required, email } from '@validations'

import { TheMask } from 'vue-the-mask'

import Cleave from 'cleave.js';

import useJwt from '@/auth/jwt/useJwt'

import VueCurrencyFilter from 'vue-currency-filter'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


Vue.use(VueCurrencyFilter, {
  symbol: 'R$',
  thousandsSeparator: '.',
  fractionCount: 2,
  fractionSeparator: ',',
  symbolPosition: 'front',
  symbolSpacing: true,
})



localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      cartaoNome: {
        required: 'O nome é obrigatório.',
      },
      cartaoNumero: {
        required: 'O número do cartão é obrigatório.',
      },
      cartaoValidade: {
        required: 'Validade é obrigatória.',
      },
      cartaoCvv: {
        required: 'CVV é obrigatório.',
      },
      enderecoCep: {
        required: 'CEP é obrigatório.',
      },
      enderecoLogradouro: {
        required: 'Logradouro é obrigatório.',
      },
      enderecoNumero: {
        required: 'Número é obrigatório.',
      },
      enderecoBairro: {
        required: 'Bairro é obrigatório.',
      },
      enderecoCidade: {
        required: 'Cidade é obrigatória.',
      },
      enderecoEstado: {
        required: 'Estado é obrigatório.',
      },
    },
  },
})

export default {
  components: {
    BAlert,
    BRow,
    BImg,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BCardTitle,
    BForm,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BBadge,
    BLink,
    BSpinner,
    BModal,
    VueCreditCard,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    TheMask,
    VueCurrencyFilter,
    ToastificationContent,
  },
  data() {
    return {
      invalid: false,
      previaCartaoNome: 'Seu Nome',
      previaCartaoNumero: 'XXXX XXXX XXXX XXXX',
      previaCartaoValidade: 'MM/AA',
      previaCartaoCvv: '123',
      cartaoBandeira: '',
      cartaoBandeiraImagem: 'sem-bandeira.png',
      cartaoNome: '',
      cartaoNumero: '',
      cartaoValidade: '',
      cartaoCvv: '',
      cartaoPaymentToken: '',
      cartaoFinalCartao: '',
      card: {
        brand: '',
        number: '',
        cvv: '',
        expiration_month: '',
        expiration_year: '',
        reuse: false,
      },
      consulta: null,
      respostaApi: false,
      enderecoCep: '',
      enderecoLogradouro: '',
      enderecoNumero: '',
      enderecoBairro: '',
      enderecoComplemento: '',
      enderecoCidade: '',
      enderecoEstado: '',
      planos: [],
      planoId: router.currentRoute.params.id,
      planoSelecionado: [],
      pixGerado: null,
      pixQrcode: null,
      pixCodigo: null,
      pixContador: null,
    }
  },
  watch: {
    enderecoCep(novoValor, valorAntigo) {
      if (novoValor.length === 8) {
        fetch(`https://viacep.com.br/ws/${this.enderecoCep}/json/`)
          .then(response => response.json())
          .then(data => {
            this.enderecoLogradouro = data.logradouro;
            this.enderecoBairro = data.bairro;
            this.enderecoCidade = data.localidade;
            this.enderecoEstado = data.uf;
          })
          .catch(error => console.error(error));
      }
    },
    
  },
  computed: {
    minutes() {
      return String(Math.floor(this.pixContador / 60)).padStart(2, '0');
    },
    seconds() {
      return String(this.pixContador % 60).padStart(2, '0');
    },
  },
  mounted() {

    // Verificar Endereço
    const usuarioData = JSON.parse(localStorage.getItem('usuarioData'))
    const completarCadastro = usuarioData.completarCadastro

    if (completarCadastro === true) {
      this.modalCompletarEndereco()
    }

    // Verificar Plano Selecionado

    useJwt.assinaturaPlanosLista({
        // page: this.page,
        // size: this.size,
        // sort: this.sort,
      })
    .then(response => {

      this.planos = response.data.content

      //console.log(this.planos)
    
    })
    .catch(error => {
      console.log(error)
    })
    .finally(() => {

      let planoSelecionado = null;

      for (let i = 0; i < this.planos.length; i++) {
        if (this.planos[i].id == this.planoId) {
          planoSelecionado = this.planos[i];
          break;
        }
      }

      if (planoSelecionado) {
        
        this.planoSelecionado = {
          credito: planoSelecionado.credito,
          nome: planoSelecionado.nome,
          valor: planoSelecionado.valor
        }

      } else {

        console.log("Plano não encontrado com o ID:", this.planoId);

      }

    })

    // Verificar Endereço

  },
  methods: {

    // Modal Completar Cadastro

    modalCompletarEndereco() {

      this.$refs.modalCompletarEndereco.show()

    },

    // Modal Completar Cadastro

    // Consultar CEP

    consultarCEP() {
      fetch(`https://viacep.com.br/ws/${this.cep}/json/`)
        .then(response => response.json())
        .then(data => {
          this.enderecoLogradouro = data.logradouro;
          this.enderecoBairro = data.bairro;
          this.enderecoCidade = data.localidade;
          this.enderecoEstado = data.uf;
        })
        .catch(error => console.error(error));
    },
    
    // Consultar CEP


    redirecionarPlanos() {
      this.$router.push({ name: 'financeiro-recarregar-planos' });
    },

    // Cadastrar Endereço

    cadastrarEndereco() {

      this.$refs.enderecoForm.validate().then(success => {
        if (success) {
          
          this.respostaApi = true

          useJwt.compositorEndereco({
            logradouro: this.enderecoLogradouro,
            numero: this.enderecoNumero,
            bairro: this.enderecoBairro,
            cidade: this.enderecoCidade,
            estado: this.enderecoEstado,
            complemento: this.enderecoComplemento,
            cep: this.enderecoCep,
          })
          .then(response => {

            const usuarioData = JSON.parse(localStorage.getItem('usuarioData'));
            usuarioData.completarCadastro = false;
            localStorage.setItem('usuarioData', JSON.stringify(usuarioData));

            this.$swal({
              title: 'Endereço cadastrado!',
              text: 'Seu cadastrado foi atualizado.',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            }).then(result => {
              if (result.isConfirmed) {
                this.$refs.modalCompletarEndereco.hide()
              } else {
                this.$refs.modalCompletarEndereco.hide()
              }
            })

          })
          .catch(error => {

            this.$swal({
              title: 'Erro!',
              text: 'Ocorreu um erro. Tente novamente.',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })

          })
          .finally(() => {
            this.respostaApi = false
          })

        }

      })

    },

    // Cadastrar Endereço

    // Gerar Pix

    recarregarPix() {

      this.$refs.gerandoPix.show()

      // API - GERAR PIX

      useJwt.assinaturaPixGerar({
        id: this.planoId
      })
        .then(response => {


          this.pixGerado = true
          this.pixQrcode = response.data.imagemQrcode
          this.pixCodigo = response.data.qrcode
          this.pixContador = response.data.expiracao

          this.contadorPixExpiracao() //chamando contador
          this.verificarPagamentoPix() //chamando verificar status pagamento

          //IR ATÉ O ELEMENTO
          this.$refs.cardPix.scrollIntoView({ behavior: 'smooth' })

          // CONTADOR EXPIRAÇÃO


        })
        .catch(error => {
        // console.log( error.response.data.message );

          this.$swal({
            title: 'Ops! Aconteceu um erro.',
            text: error.response.data.message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .finally(() => {

          this.$refs.gerandoPix.hide()        

        })

      // API - GERAR PIX



    },

    // Gerar Pix

    copiarPixCodigo() {
      this.$refs.textCodigo.focus()
      document.execCommand('copy')

      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: 'Copiado com sucesso!',
          icon: 'CopyIcon',
          variant: 'success',
        },
      })

    },

    // Contador de expiração do Pix

    contadorPixExpiracao() {
      this.intervalId = setInterval(() => {
        // Reduza o contador em 1 segundo a cada intervalo
        this.pixContador--

        // Verifique se o contador chegou a zero
        if (this.pixContador === 0) {
          // Execute a ação desejada quando o contador chegar a zero
          clearInterval(this.intervalId) // Pare o intervalo
          // Por exemplo, exiba uma mensagem ou execute uma função
        }
      }, 1000); // Atualize a cada segundo (1000 milissegundos)
    },

    verificarPagamentoPix() {

      this.verificarStatusPagamentoId = setInterval(() => {
        
        // VERIFICANDO

        // API - STATUS PAGAMENTO PIX

        useJwt.assinaturaPixStatusPagamento({
          // id: this.planoId
        })
          .then(response => {

            // console.log(response.data.status_pagamento)

            const statusPagamentoAtual = response.data.status_pagamento

            // DEPOIS QUE PAGO
            if (statusPagamentoAtual == 'PIX_CONCLUIDA') {

              // Execute a ação desejada quando PIX_CONCLUIDA
              clearInterval(this.verificarStatusPagamentoId) // Pare o intervalo

              this.$swal({
                title: 'Pagamento Confirmado!',
                text: 'Recebemos o seu pagamento, o seu saldo será creditado na sua conta.',
                icon: 'success',
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              }).then(result => {
                if (result.isConfirmed) {
                  this.$router.push({ name: 'financeiro-saldo' })
                } else {
                  // PASSA PARA PRÓXIMA GUIA
                  this.$router.push({ name: 'financeiro-saldo' })
                }
              })
              

            }
          
          })
          .catch(error => {
            // console.log( error.response.data.message );
          })
          .finally(() => {

            // this.$refs.gerandoPix.hide()        

          })

        // API - STATUS PAGAMENTO PIX
        
      }, 3000); // Atualize a cada segundo (1000 milissegundos)

    },


  },

  beforeDestroy() {
    // Certifique-se de limpar o intervalo quando o componente for destruído
    clearInterval(this.intervalId)
    clearInterval(this.verificarStatusPagamentoId)
  },


}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
//@import '@fracto/vue-credit-card/';

.card.selecionarPagamento:hover {
  box-shadow: 0 0 5px 1px rgb(254 152 86 / 40%) !important;
  border-color: #ff7300 !important;
}


</style>
